import {NbMenuItem} from '@nebular/theme';

interface TabParams {
  page?: number;
  size?: number;
  direction?: 'desc' | 'asc';
  sort?: string;
}

function fillParams(args: TabParams & {[key: string]: any} = {}) {
  return {
    page: 0,
    size: 10,
    sort: 'contractId',
    direction: 'desc',
    ...args
  };
}

export const MENU_ITEMS_INTERNAL_AGENT: NbMenuItem[] = [
  {
    title: 'CLOSE',
    icon: 'close-outline',
    data: 'collapseMenu'
  },
  {
    title: 'HOME',
    icon: 'home-outline',
    link: '/pages/dashboard',
    home: true
  },
  {
    title: 'Infleet',
    icon: {
      icon: 'settings',
      pack: 'allane'
    },
    children: [
      {
        title: 'Order',
        link: '/fleet/in/orders',
        queryParams: fillParams({
          tab: 'NO_VIN_CODE',
          sort: 'dateOfCreation'
        })
      },
      {
        title: 'Dealer Handover',
        link: '/fleet/in/dealer/orders',
        queryParams: fillParams({
          tab: 'NO_VIN_CODE',
          sort: 'dateOfCreation'
        })
      },
      {
        title: 'Damage Warnings',
        link: '/fleet/warnings',
        queryParams: fillParams({
          sort: 'requestDate',
          tab: 'NEW'
        })
      },
      {
        title: 'Error Warnings',
        link: '/fleet/integration-warnings',
        queryParams: fillParams({
          sort: 'createdTime',
          tab: 'NEW'
        })
      },
      {
        title: 'Request Order',
        link: '/fleet/in/request-order'
      }
    ]
  },
  {
    title: 'Defleet',
    icon: {
      icon: 'settings',
      pack: 'allane'
    },
    children: [
      {
        title: 'Order',
        link: '/fleet/de/orders',
        queryParams: fillParams({
          sort: 'orderCreatedDate',
          tab: 'INITIATED'
        })
      },
      {
        title: 'Damage Warnings',
        link: '/fleet/warnings',
        queryParams: fillParams({
          sort: 'requestDate',
          tab: 'NEW'
        })
      },
      {
        title: 'Error Warnings',
        link: '/fleet/integration-warnings',
        queryParams: fillParams({
          sort: 'createdTime',
          tab: 'NEW'
        })
      },
      {
        title: 'Request Order',
        link: '/fleet/de/request-order'
      }
    ]
  },
  {
    title: 'Transport',
    icon: {
      icon: 'transport',
      pack: 'allane'
    },
    children: [
      {
        title: 'Order',
        link: '/fleet-transports/internal/orders',
        queryParams: fillParams({
          tab: 'INCOMPLETE',
          sort: 'contractId',
          size: 10,
          direction: 'asc'
        })
      },
      {
        title: 'Report',
        link: '/fleet-transports/internal/report'
      }
    ]
  },
  {
    title: 'DAD Errors',
    icon: {
      icon: 'attention',
      pack: 'allane'
    },
    children: [
      {
        title: 'DAD Warnings',
        link: '/dad-warnings',
        queryParams: fillParams({
          sort: 'contractId'
        })
      },
      {
        title: 'DAD Responses',
        link: '/dad-responses',
        queryParams: fillParams({
          sort: 'requestTime',
          direction: 'desc',
          size: 100
        })
      },
    ]
  },
  {
    title: 'De-Registration',
    icon: {
      icon: 'settings',
      pack: 'allane'
    },
    children: [
      {
        title: 'Order',
        link: '/de-registration/internal/order',
        queryParams: fillParams({
          tab: 'STANDARD',
          sort: 'carDropOffDate'
        })
      },
      {
        title: 'Task',
        link: '/de-registration/internal/task',
        queryParams: fillParams({
          tab: 'TODO',
          sort: 'leadTime'
        })
      },
      {
        title: 'File Import',
        link: '/de-registration/internal/import'
      },
      {
        title: 'Report',
        link: '/de-registration/internal/report'
      },
      {
        title: 'Shipment List',
        link: '/de-registration/internal/shipment-list',
        queryParams: fillParams({
          sort: 'documentCreatedDateTime',
          size: 100,
          direction: 'desc'
        })
      }
    ]
  },
  {
    title: 'Appointments',
    icon: {
      icon: 'appointments',
      pack: 'allane'
    },
    link: '/appointments/calendar',
  }
];
export const MENU_ITEMS_INTERNAL_TEAM_LEAD: NbMenuItem[] = [
  {
    title: 'User Management',
    icon: {
      icon: 'lock',
      pack: 'allane'
    },
    children: [
      {
        title: 'External Users',
        link: '/user-management/external',
        queryParams: fillParams({
          sort: 'firstName,lastName',
          direction: 'asc'
        })
      },
      {
        title: 'Internal Users',
        link: '/user-management/internal',
        queryParams: fillParams({
          sort: 'firstName,lastName',
          direction: 'asc'
        })
      },
      {
        title: 'History',
        link: '/user-management/history',
        queryParams: fillParams({
          sort: 'changeAt',
          direction: 'desc',
          size: 50,
          tab: 'INTERNAL'
        })
      }
    ]
  },
  {
    title: 'Supplier Management',
    icon: {
      icon: 'lock',
      pack: 'allane'
    },
    link: '/supplier-management',
    children: [
      {
        title: 'Vendors',
        link: '/supplier-management',
        queryParams: fillParams({
          tab: 'VENDORS',
          size: 20,
          sort: 'id'
        })
      }, {
        title: 'Service Package',
        link: '/supplier-management',
        queryParams: fillParams({
          tab: 'SERVICE_PACKAGE',
          size: 20,
          sort: 'id'
        })
      }, {
        title: 'Service Provider',
        link: '/supplier-management',
        queryParams: fillParams({
          tab: 'SERVICE_PROVIDER',
          size: 50,
          sort: 'id'
        })
      }, {
        title: 'Materials',
        link: '/supplier-management',
        queryParams: fillParams({
          tab: 'MATERIAL',
          size: 20,
          sort: 'materialId'
        })
      }, {
        title: 'Infleet/Defleet Locations',
        link: '/supplier-management',
        queryParams: fillParams({
          tab: 'LOCATIONS',
          size: 20,
          sort: 'city',
          direction: 'asc'
        })
      }
    ]
  }
];
export const MENU_ITEMS_INTERNAL_ADMIN: NbMenuItem[] = [
  {
    title: 'Resources Management',
    icon: {
      icon: 'lock',
      pack: 'allane'
    },
    link: '/resources-management',
    children: [
      {
        title: 'Appointment Resources',
        link: '/resources-management',
        queryParams: fillParams({
          tab: 'APPOINTMENT_RESOURCES',
          size: 20,
          direction: 'asc',
          sort: 'name'
        })
      },
      {
        title: 'Human Resources',
        link: '/resources-management',
        queryParams: fillParams({
          tab: 'HUMAN_RESOURCES',
          size: 20,
          direction: 'asc',
          sort: 'firstName'
        })
      }
    ]
  }
];
export const MENU_ITEMS_EXTERNAL_USER: NbMenuItem[] = [
  {
    title: ' ',
    icon: 'close-outline',
    data: 'collapseMenu'
  },
  {
    title: 'Home',
    icon: 'home-outline',
    link: '/pages/dashboard',
    home: true
  },
  {
    title: 'De-Registration',
    icon: {
      icon: 'settings',
      pack: 'allane'
    },
    children: [
      {
        title: 'Create Shipment',
        link: '/de-registration/vendor/create-shipment'
      },
      {
        title: 'Shipments',
        link: '/de-registration/vendor/shipments',
        queryParams: fillParams({
          sort: 'documentCreatedDateTime',
          size: 100,
          direction: 'desc'
        })
      }
    ]
  },
  {
    title: 'Defleet',
    icon: {
      icon: 'settings',
      pack: 'allane'
    },
    children: [
      {
        title: 'Request Order',
        link: '/fleet/de/request-order'
      }
    ]
  },
  {
    title: 'Infleet',
    icon: {
      icon: 'settings',
      pack: 'allane'
    },
    children: [
      {
        title: 'Request Order',
        link: '/fleet/in/request-order'
      }
    ]
  },
  {
    title: 'Appointments',
    icon: {
      icon: 'appointments',
      pack: 'allane'
    },
    link: '/appointments/calendar',
  },
  {
    title: 'Transport',
    icon: {
      icon: 'transport',
      pack: 'allane'
    },
    children: [
      {
        title: 'Order',
        link: '/fleet-transports/vendor/orders',
        queryParams: fillParams({
          tab: 'NEW_ORDERS',
          sort: 'orderDate',
          size: 50,
          direction: 'asc'
        })
      }
    ]
  },
];

export class BackendPermissionMenuMap {
  static dashboard = '/pages/dashboard';
  static contractOrders = '/contracts';

  static fleetTransport = '/fleet-transports/internal/orders';
  static fleetTransportReport = '/fleet-transports/internal/report';
  static fleetTransportExternal = '/fleet-transports/vendor/orders';

  static resourcesManagement = '/resources-management';

  static appointments = '/appointments/calendar';

  static registrationOrders = '/registrations/order';
  static registrationTasks = '/registrations/task';

  static dadWarnings = '/dad-warnings';
  static soapProxyRequests = '/dad-responses';

  static inFleetOrders = '/fleet/in/orders';
  static inFleetDealerOrders = '/fleet/in/dealer/orders';
  static deFleetOrders = '/fleet/de/orders';
  static deFleetRequestOrder = '/fleet/de/request-order';
  static inFleetRequestOrder = '/fleet/in/request-order';
  static fleetDamageWarnings = '/fleet/warnings';
  static fleetIntegrationWarnings = '/fleet/integration-warnings';

  static deRegistrationInternalOrders = '/de-registration/internal/order';
  static deRegistrationInternalTasks = '/de-registration/internal/task';
  static deRegistrationInternalShipments = '/de-registration/internal/shipment-list';
  static deRegistrationInternalTasksReport = '/de-registration/internal/report';
  static deRegistrationImport = '/de-registration/internal/import';
  static deRegistrationVendorCreateShipment = '/de-registration/vendor/create-shipment';
  static deRegistrationVendorShipments = '/de-registration/vendor/shipments';

  static userManagementExternal = '/user-management/external';
  static userManagementInternal = '/user-management/internal';
  static userManagementHistory = '/user-management/history';

  static supplierManagement = '/supplier-management';
}
