import {APP_BASE_HREF} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NbIconLibraries } from '@nebular/theme';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreModule} from 'app/@core/core.module';
import {UserService} from 'app/@core/utils/user.service';
import {ThemeModule} from 'app/@theme/theme.module';
import {AppRoutingModule} from 'app/app-routing.module';

import {AppComponent} from 'app/app.component';
import {MainAuthGuard, RoleAuthGuard} from 'app/main-auth-guard.service';
import {AlertService} from 'app/service/alert.service';
import {BackofficeService} from 'app/service/backoffice.service';
import {BpmnService} from 'app/service/bpmn.service';
import {DADService} from 'app/service/dad.service';
import {FileUploadService} from 'app/service/file-upload.service';
import {FormattingService} from 'app/service/formatting.service';
import {OrderService} from 'app/service/order.service';
import {SapService} from 'app/service/sap.service';
import {CachingInterceptor} from 'app/ui/helpers/caching.interceptor';
import {ErrorInterceptor} from 'app/ui/helpers/error.interceptor';
import {JwtInterceptor} from 'app/ui/helpers/jwt.interceptor';
import {RequestCacheService} from 'app/ui/helpers/request-cache.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    ThemeModule.forRoot(),
    CoreModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    RequestCacheService,
    {provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true},
    MainAuthGuard, RoleAuthGuard,
    OrderService, DADService, BpmnService, SapService, AlertService,
    BackofficeService, FileUploadService, FormattingService,
    {provide: APP_BASE_HREF, useValue: '/'},
    /*{provide: Ng2SmartTableComponent, useClass: SmartTableComponent},*/
    UserService
  ],
  entryComponents: []
})
export class AppModule {
  constructor(iconsLibrary: NbIconLibraries) { 
    iconsLibrary.registerSvgPack('allane', { 
      'appointments': APPOINTMENTS_SVG, 
      'attention': ATTENTION_SVG, 
      'lock': LOCK_SVG, 
      'settings': SETTINGS_SVG,
      'transport': TRANSPORT_SVG,
      'search': SEARCH_SVG
    });
  }
}


const SETTINGS_SVG = `
<svg class="menu-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" fill="none">
  <rect x="0.5" y="1" stroke="#1A103F"/>
  <path d="M9.08004 15.1667H6.92004L6.12671 13.6933C5.96004 13.6333 5.80004 13.5667 5.65337 13.5L4.04671 13.9867L2.52004 12.46L3.00671 10.8533C2.93337 10.7067 2.86671 10.5467 2.80671 10.38L1.33337 9.58668V7.42668L2.80671 6.63335C2.86671 6.46668 2.93337 6.30668 3.00671 6.16001L2.52004 4.55335L4.04671 3.02668L5.65337 3.51335C5.80671 3.44001 5.96004 3.37335 6.12671 3.32001L6.92004 1.84668H9.08004L9.87337 3.32001C10.04 3.38001 10.2 3.44668 10.3467 3.51335L11.9534 3.02668L13.48 4.55335L12.9934 6.16001C13.0667 6.31335 13.1334 6.46668 13.1867 6.63335L14.66 7.42668V9.58668L13.1867 10.38C13.1267 10.5467 13.06 10.7067 12.9934 10.8533L13.48 12.46L11.9534 13.9867L10.3467 13.5C10.2 13.5733 10.04 13.6333 9.87337 13.6933L9.08004 15.1667ZM7.32004 14.5H8.68004L9.40671 13.1467L9.53337 13.1067C9.76671 13.0267 9.97337 12.9467 10.16 12.8467L10.28 12.7867L11.76 13.2333L12.7267 12.2667L12.28 10.7867L12.34 10.6667C12.44 10.4733 12.5267 10.2667 12.6 10.04L12.64 9.91335L13.9934 9.18668V7.82668L12.64 7.10001L12.6 6.97335C12.5267 6.74668 12.44 6.54001 12.34 6.34668L12.28 6.22668L12.7267 4.74668L11.76 3.78001L10.28 4.22668L10.16 4.16668C9.96671 4.06668 9.76004 3.98001 9.53337 3.90668L9.40671 3.86668L8.68004 2.51335H7.32004L6.59337 3.86668L6.46671 3.90668C6.24004 3.98001 6.03337 4.06668 5.84004 4.16668L5.72004 4.22668L4.24004 3.78001L3.27337 4.74001L3.72004 6.22001L3.66004 6.34001C3.56004 6.53335 3.47337 6.74001 3.40004 6.96668L3.36004 7.09335L2.00671 7.82001V9.18001L3.36004 9.90668L3.40004 10.0333C3.48004 10.2667 3.56004 10.4733 3.66004 10.66L3.72004 10.78L3.27337 12.26L4.24004 13.22L5.72004 12.7733L5.84004 12.8333C6.03337 12.9333 6.23337 13.02 6.46671 13.0933L6.59337 13.1333L7.32004 14.4867V14.5Z"/>
  <path d="M8.00004 12.5267C5.78004 12.5267 3.97337 10.72 3.97337 8.50001C3.97337 6.28001 5.78004 4.47335 8.00004 4.47335C10.22 4.47335 12.0267 6.28001 12.0267 8.50001C12.0267 10.72 10.22 12.5267 8.00004 12.5267ZM8.00004 5.14001C6.14671 5.14001 4.64004 6.64668 4.64004 8.50001C4.64004 10.3533 6.14671 11.86 8.00004 11.86C9.85337 11.86 11.36 10.3533 11.36 8.50001C11.36 6.64668 9.85337 5.14001 8.00004 5.14001Z"/>
</svg>
`;

const TRANSPORT_SVG = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" fill="none">
  <path d="M10.46 11.1602V9.16683H12.66V10.2802L10.46 11.1602ZM10.9933 9.70016V10.3735L12.1266 9.92016V9.70016H10.9933Z"/>
  <path d="M5.53329 11.1602L3.33329 10.2802V9.16683H5.53329V11.1602ZM3.86662 9.92016L4.99995 10.3735V9.70016H3.86662V9.92016Z"/>
  <path d="M9.49329 9.8335H6.49329V10.5002H9.49329V9.8335Z"/>
  <path d="M13.3133 7.3735L12.5666 3.8335H3.43329L2.68662 7.3735L1.99329 8.4735V13.1668H2.00662V14.5002H4.64662V13.1668H11.34V14.5002H14.0066V8.4735L13.3133 7.3735ZM3.97329 4.50016H12.0266L12.5933 7.16683H3.40662L3.97329 4.50016ZM3.97329 13.8335H2.66662V13.1668H3.97329V13.8335ZM13.3333 13.8335H12V13.1668H13.3333V13.8335ZM2.65995 12.5002V8.66016L3.18662 7.8335H12.8133L13.34 8.66016V12.5002H2.65995Z"/>
</svg>
`;

const ATTENTION_SVG = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" fill="none">
  <path d="M8.00004 15.1668C4.32671 15.1668 1.33337 12.1735 1.33337 8.50016C1.33337 4.82683 4.32671 1.8335 8.00004 1.8335C11.6734 1.8335 14.6667 4.82683 14.6667 8.50016C14.6667 12.1735 11.6734 15.1668 8.00004 15.1668ZM8.00004 2.50016C4.69337 2.50016 2.00004 5.1935 2.00004 8.50016C2.00004 11.8068 4.69337 14.5002 8.00004 14.5002C11.3067 14.5002 14 11.8068 14 8.50016C14 5.1935 11.3067 2.50016 8.00004 2.50016Z"/>
  <path d="M7.60004 11.4335C7.60004 11.2068 7.77337 11.0335 8.00004 11.0335C8.22671 11.0335 8.40004 11.2068 8.40004 11.4335C8.40004 11.6602 8.22671 11.8335 8.00004 11.8335C7.77337 11.8335 7.60004 11.6602 7.60004 11.4335ZM7.63337 8.78016V5.16683H8.36671V8.78016L8.23337 10.0202H7.76671L7.63337 8.78016Z"/>
</svg>
`;

const APPOINTMENTS_SVG = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" fill="none">
  <path d="M14 3.82L13.9867 14.5H11.98V13.8333H13.32V7.16667H6.28V6.5H13.3333V4.48667H11.3133V5.16667H10.6533V4.48667H5.32V5.16667H4.66V4.48667H2.68V6.5H4.02667V7.16667H2.66667V13.8333H9.65333V14.5H2L2.01333 3.82H4.66V2.5H5.32V3.82H10.6533V2.5H11.3133V3.82H14Z"/>
  <path d="M11.6533 8.67333H10.6533V9.67333H11.6533V8.67333Z"/>
  <path d="M8.49333 8.66667H7.49333V9.66667H8.49333V8.66667Z"/>
  <path d="M5.32667 8.66667H4.32667V9.66667H5.32667V8.66667Z"/>
  <path d="M8.49333 11.3333H7.49333V12.3333H8.49333V11.3333Z"/>
  <path d="M5.32667 11.3333H4.32667V12.3333H5.32667V11.3333Z"/>
</svg>
`;

const LOCK_SVG = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" fill="none">
  <path d="M9.33329 13.1535L8.83996 9.46683C9.12663 9.22683 9.29329 8.86683 9.29329 8.48683C9.29329 7.76683 8.71329 7.18683 7.99329 7.18683C7.27329 7.18683 6.69329 7.76683 6.69329 8.48683C6.69329 8.8535 6.85329 9.20016 7.11329 9.44016L6.65329 13.1535H9.33329ZM7.99329 7.78683C8.37996 7.78683 8.69329 8.10016 8.69329 8.48683C8.69329 8.74683 8.54663 8.98683 8.31329 9.10683L8.17996 9.14683L8.60663 12.5135H7.37329L7.78663 9.14683L7.67329 9.10683C7.43996 8.98683 7.29329 8.74683 7.29329 8.48683C7.29329 8.10016 7.60663 7.78683 7.99329 7.78683Z"/>
  <path d="M12.6666 13.1668V14.5002H3.33329V5.8335H12.6666V10.8335H13.3333V5.16683H10.7666C10.8 4.9935 10.8133 4.82016 10.8133 4.64683C10.8133 3.10016 9.55329 1.8335 7.99996 1.8335C6.44663 1.8335 5.18663 3.10016 5.18663 4.64683C5.18663 4.82016 5.19996 4.9935 5.23329 5.16683H2.66663V15.1668H13.3333V13.1668H12.6666ZM7.99996 2.50016C9.18663 2.50016 10.1466 3.46683 10.1466 4.64683C10.1466 4.82683 10.1266 5.00016 10.08 5.16683H5.91996C5.87329 5.00016 5.85329 4.82683 5.85329 4.64683C5.85329 3.46683 6.81329 2.50016 7.99996 2.50016Z"/>
</svg>
`;

const SEARCH_SVG = `
<svg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.16667 11.0833V10.15C4.4 9.75 3.78333 9.10834 3.43333 8.325H2.53333C2.98333 9.575 3.94167 10.5833 5.175 11.0917L5.16667 11.0833Z" />
    <path d="M11.4583 10.8417C12.425 9.74167 13 8.31667 13 6.75C13 5.64167 12.7083 4.60834 12.2 3.7L11.5833 4.31667C11.95 5.05 12.1667 5.875 12.1667 6.74167C12.1667 9.725 9.73333 12.1583 6.75 12.1583C3.76667 12.1583 1.33333 9.725 1.33333 6.74167C1.33333 3.75834 3.76667 1.325 6.75 1.325C7.8 1.325 8.78333 1.63334 9.61667 2.15834L10.225 1.55C9.23333 0.883336 8.03333 0.491669 6.75 0.491669C3.3 0.491669 0.5 3.29167 0.5 6.74167C0.5 10.1917 3.3 12.9917 6.75 12.9917C8.325 12.9917 9.76667 12.4083 10.8667 11.425L14.9083 15.4917L15.2083 15.2L15.5 14.9L11.4583 10.8333V10.8417Z" />
</svg>
`;