<div class="d-flex justify-content-center">
  <form #form="ngForm" (ngSubmit)="onSearch(form)">
    <mat-form-field>
      <mat-label style="display: flex; align-items: center; justify-content: space-between;">
        Search type
        <!-- <mat-icon style="color: white;" svgIcon="lsp-back"></mat-icon> -->
         <span class="fas fa-chevron-down" style="font-size: 16px;"></span>
      </mat-label>
      <mat-select [(ngModel)]="searchType"
                  [(value)]="searchType"
                  class="search-select"
                  name="searchType"
                  ngModel>
        <mat-option value="CONTRACT_ID">LVID</mat-option>
        <mat-option value="LICENSE_PLATE">LICENСE PLATE NUMBER</mat-option>
        <mat-option value="VIN">VIN</mat-option>
        <mat-option value="CUSTOMER_NAME">CUSTOMER NAME</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="container-fluid mt-auto search-input ml-2">
      <input [(ngModel)]="searchInput"
             fullWidth
             matInput
             minlength="3"
             name="searchInput"
             ngModel
             placeholder="Search for..."
      >
    </mat-form-field>
    <nb-icon (click)="onSearch(form)" class="search-icon"
             icon="search"
             pack="allane"
    ></nb-icon>
    
  </form>
</div>
